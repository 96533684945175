export const firebaseConfig = {
  apiKey: "AIzaSyAOzmGrxrFPBIF7oHPTWEHwSUnQNC9UO0o",
  authDomain: "mcmillan-1.firebaseapp.com",
  databaseURL: "https://mcmillan-1.firebaseio.com",
  projectId: "mcmillan-1",
  storageBucket: "mcmillan-1.appspot.com",
  messagingSenderId: "440554460723",
  appId: "1:440554460723:web:ce09b3654bae51246f7546"
};

export const vapidKey = `BAJp49D3bEy06Z684awSXYGsGHC1IGA4N3H0Zs4wZkW_3UkgJjXSNgpah1bSMKSUpd_fvOW8ZjaZyBB04A29RgU`;

// ...

const region = 'us-central1';

export function getCloudFunctionUrl(cfName) {
  if (process.env.REACT_APP_TOKEN_EMULATOR === 'true') {
    return `http://localhost:5001/${firebaseConfig.projectId}/${region}/${cfName}`;
  } 

  return `https://${region}-${firebaseConfig.projectId}.cloudfunctions.net/${cfName}`;
}